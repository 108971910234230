import DashboardIcon from "@mui/icons-material/Dashboard";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";

export const mainListItems = (
  <React.Fragment>
    <ListItemButton>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="足彩数据" />
    </ListItemButton>
    {/*<ListItemButton>*/}
    {/*  <ListItemIcon>*/}
    {/*    <ShoppingCartIcon />*/}
    {/*  </ListItemIcon>*/}
    {/*  <ListItemText primary="Orders" />*/}
    {/*</ListItemButton>*/}
    {/*<ListItemButton>*/}
    {/*  <ListItemIcon>*/}
    {/*    <PeopleIcon />*/}
    {/*  </ListItemIcon>*/}
    {/*  <ListItemText primary="Customers" />*/}
    {/*</ListItemButton>*/}
    {/*<ListItemButton>*/}
    {/*  <ListItemIcon>*/}
    {/*    <BarChartIcon />*/}
    {/*  </ListItemIcon>*/}
    {/*  <ListItemText primary="Reports" />*/}
    {/*</ListItemButton>*/}
    {/*<ListItemButton>*/}
    {/*  <ListItemIcon>*/}
    {/*    <LayersIcon />*/}
    {/*  </ListItemIcon>*/}
    {/*  <ListItemText primary="Integrations" />*/}
    {/*</ListItemButton>*/}
  </React.Fragment>
);

// export const secondaryListItems = (
//   <React.Fragment>
//     <ListSubheader component="div" inset>
//       Saved reports
//     </ListSubheader>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Current month" />
//     </ListItemButton>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Last quarter" />
//     </ListItemButton>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Year-end sale" />
//     </ListItemButton>
//   </React.Fragment>
// );
