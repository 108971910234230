import React from "react";

interface MatchListResponse {
  value?: {
    matchResult: any[];
    total?: number;
  };
}

interface OddsEntry {
  oddsType: "HAD" | "HHAD";
  matchId: string;

  [key: string]: string; // 用于表示赔率数据的其他属性
}

interface OddsDictionary {
  hadList: OddsEntry[];
  hhadList: OddsEntry[];
}

const PAGE_SIZE = "100";

export async function getExistMatchInfoMatchIds(
  beginDate: string,
  endDate: string
) {
  const url = `${BACKEND_URL}/getExistMatchInfoMatchIds?matchBeginDate=${beginDate}&matchEndDate=${endDate}`;
  const response = await fetch(url);
  return await response.json();
}

const stepDays = 30;

export function splitDateRange(
  beginDate: string,
  endDate: string,
  rangeDays = stepDays
): Array<{ begin: string; end: string }> {
  const dateRanges = [];
  let startDate = new Date(beginDate);
  const endDateObj = new Date(endDate);

  while (startDate <= endDateObj) {
    const nextDate = new Date(startDate);
    nextDate.setDate(startDate.getDate() + rangeDays - 1); // Add 29 days to the current date so total range is 30 days.
    const subrangeEnd = nextDate > endDateObj ? endDateObj : nextDate;
    const subRange = {
      begin: formatDate(startDate),
      end: formatDate(subrangeEnd),
    };
    dateRanges.push(subRange);

    console.log("subRange:", subRange);

    if (subrangeEnd >= endDateObj) {
      break;
    }

    // Set startDate to the day after nextDate
    // startDate.setDate(subrangeEnd.getDate() + 1); // error in a quirk of the Date object in JavaScript. When the day value set for a date exceeds the number of days in its month, it will roll over to the next month.
    startDate = new Date(subrangeEnd.getTime() + 24 * 60 * 60 * 1000); // Adds one day in milliseconds
    console.log("nextStartDate:", formatDate(startDate));
  }

  return dateRanges;
}

// Helper function to format date objects as "YYYY-MM-DD".
function formatDate(date: Date): string {
  const yyyy = date.getFullYear().toString();
  const mm = (date.getMonth() + 1).toString().padStart(2, "0");
  const dd = date.getDate().toString().padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;
}

// 获取官方数据
export async function fetchMatchListPage(
  page: number,
  beginDate: string,
  endDate: string,
  size: string
): Promise<MatchListResponse> {
  const apiUrl = `https://webapi.sporttery.cn/gateway/jc/football/getMatchResultV1.qry?matchPage=1&matchBeginDate=${beginDate}&matchEndDate=${endDate}&pageSize=${size}&pageNo=${page}&isFix=0&pcOrWap=1`;

  const response = await fetch(apiUrl);
  return await response.json();
}

// export const BACKEND_URL = "http://127.0.0.1:8787";
// export const BACKEND_URL =
//   "https://cf_football_data_fetcher.kuanhsiaokuo.workers.dev";
export const BACKEND_URL = "https://lottery-data-fetcher.flowingnote.com";

// 获取官方数据后提交给服务器
export async function fetchDataAndSend(
  page: number,
  beginDate: string,
  endDate: string,
  existMatchIds: number[] = []
) {
  const data = await fetchMatchListPage(page, beginDate, endDate, PAGE_SIZE);

  // 首先检查value和matchResult是否存在
  if (data.value && data.value.matchResult) {
    // 过滤那些已经存在的matchIds
    data.value.matchResult = data.value.matchResult.filter(
      (match) => !existMatchIds.includes(match.matchId)
    );
  } else {
    // 如果value或matchResult不存在，直接返回，不进行后续的上传
    return;
  }

  const response = await fetch(`${BACKEND_URL}/updateTotalMatchList`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await response.json();
}

export async function getMissedOddsDataMatchIds() {
  const response = await fetch(`${BACKEND_URL}/getMissedOddsDataMatchIds`);
  return await response.json();
}

export async function updateMatches(beginDate: string, endDate: string) {
  // Split the date range.
  const dateRanges = splitDateRange(beginDate, endDate);
  console.log("dateRanges:", dateRanges);

  const concurrentUpdates = 10; // adjust based on your resources and needs
  const updatePromises = [];

  for (const range of dateRanges) {
    if (updatePromises.length >= concurrentUpdates) {
      await Promise.all(updatePromises);
      updatePromises.length = 0; // clear the array
    }

    console.log(
      `Starting update for the range: ${range.begin} to ${range.end}`
    );
    updatePromises.push(updateMatchesForSubrange(range.begin, range.end));
  }

  if (updatePromises.length > 0) {
    await Promise.all(updatePromises);
  }
}

async function updateMatchesForSubrange(beginDate: string, endDate: string) {
  const firstResponse = await fetchMatchListPage(
    1,
    beginDate,
    endDate,
    PAGE_SIZE
  );
  // 获取已存在的matchIds
  const existMatchInfoResponse = await getExistMatchInfoMatchIds(
    beginDate,
    endDate
  );
  console.log("existMatchInfoResponse:", existMatchInfoResponse);
  const total = firstResponse.value?.total || 0;
  const totalPages = Math.ceil(total / parseInt(PAGE_SIZE));
  // 如果已经存在的个数与官方数据不一致，才更新
  if (total > existMatchInfoResponse.total) {
    let fetchedResultsCount = (firstResponse.value?.matchResult || []).length;
    console.log(
      "beginDate:",
      beginDate,
      "endDate:",
      endDate,
      "page_size:",
      PAGE_SIZE,
      "totalPages:",
      totalPages,
      "start fetchedResultsCount",
      fetchedResultsCount
    );

    for (let page = 1; page <= totalPages; page++) {
      const response = await fetchDataAndSend(
        page,
        beginDate,
        endDate,
        existMatchInfoResponse.existMatchInfoMatchIds
      );

      // Accumulate fetched results count for logging purposes
      fetchedResultsCount += (response.matchResults || []).length;
      console.log("fetchedResultsCount:", fetchedResultsCount, "page:", page);

      if (response.message !== "Data processed successfully") {
        // Handle error or decide on next action
        break;
      }
    }
  } else {
    console.log(
      `api total: ${total}, exist total: ${existMatchInfoResponse.total}`
    );
  }
}

async function fetchOddsForMatch(matchId: string): Promise<OddsDictionary> {
  const oddsResponse = await fetch(
    `https://webapi.sporttery.cn/gateway/jc/football/getFixedBonusV1.qry?clientCode=3001&matchId=${matchId}`
  );
  const oddsData = (await oddsResponse.json()) as {
    value?: { oddsHistory?: { hadList: any; hhadList: any } };
  };

  const hadOddsType = "HAD";
  const hhadOddsType = "HHAD";

  const returnData = {
    hadList: oddsData.value?.oddsHistory?.hadList?.map(
      (odds: { [key: string]: string }) => ({
        ...odds,
        oddsType: hadOddsType,
        matchId: matchId,
      })
    ),
    hhadList: oddsData.value?.oddsHistory?.hhadList?.map(
      (odds: { [key: string]: string }) => ({
        ...odds,
        oddsType: hhadOddsType,
        matchId: matchId,
      })
    ),
  };
  return returnData;
}

// const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
export const BATCH_SIZE = 10;
export const DELAY_DURATION = 100; // 2 seconds
const oddsBatchSize = 10;
const concurrentSends = 20; // adjust as needed
const loading_line = 5;

export async function updateMissedOddsData(
  setShowLoadingDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setUpdateProgress: React.Dispatch<React.SetStateAction<number>>
): Promise<void> {
  try {
    // 1. 从服务器获取需要更新的matchId
    const missedOddsDataMatchIdsResponse = await getMissedOddsDataMatchIds();
    const missedMatchIds = missedOddsDataMatchIdsResponse.missedMatchIds;

    if (missedMatchIds.length > loading_line) {
      setShowLoadingDialog(true);
    }

    const sendData = async (data: OddsDictionary) => {
      // 你可能想先检查数据是否为空，但我会省略这一步因为下面的代码已经检查过了
      const response = await fetch(`${BACKEND_URL}/updateMatchOdds`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();
      console.log(responseData.message);
    };

    // 创建一个数组来存储所有的赔率数据
    let allOddsData: OddsDictionary = { hadList: [], hhadList: [] };

    // 2. 为每个missedMatchId获取赔率数据
    const totalMatches = missedMatchIds.length;
    for (const [index, matchId] of missedMatchIds.entries()) {
      const oddsData = await fetchOddsForMatch(matchId);

      // 将获取到的数据合并到allOddsData中，但之前先检查是否非空
      if (oddsData.hadList && oddsData.hadList.length > 0) {
        allOddsData.hadList.push(...oddsData.hadList);
      }

      if (oddsData.hhadList && oddsData.hhadList.length > 0) {
        allOddsData.hhadList.push(...oddsData.hhadList);
      }
      // Update the progress
      const progress = ((index + 1) / totalMatches) * 100;
      setUpdateProgress(progress);

      // 如果已经处理了BATCH_SIZE数量的请求，那么延迟一段时间
      if ((index + 1) % BATCH_SIZE === 0) {
        // 3. 检查allOddsData是否有数据需要更新，如果都为空，则直接返回
        if (
          allOddsData.hadList.length === 0 &&
          allOddsData.hhadList.length === 0
        ) {
          // 在函数的最后，确保弹窗被关闭
          setShowLoadingDialog(false);
          console.log("No odds data available for update.");
        } else {
          const oddsBatches = splitOddsData(allOddsData, oddsBatchSize);
          console.log("oddsDate to be updated:", allOddsData);

          // for (const batch of oddsBatches) {
          //   console.log("Batch to be updated:", batch);
          //   await sendData(batch);
          // }
          for (let i = 0; i < oddsBatches.length; i += concurrentSends) {
            const promises = [];
            for (
              let j = 0;
              j < concurrentSends && i + j < oddsBatches.length;
              j++
            ) {
              promises.push(sendData(oddsBatches[i + j]));
            }
            await Promise.all(promises);
          }

          // await delay(DELAY_DURATION); // 延迟

          // 清空allOddsData为下一个批量做准备
          allOddsData = { hadList: [], hhadList: [] };
        }
      }
    }

    // 检查是否还有数据需要发送
    if (allOddsData.hadList.length > 0 || allOddsData.hhadList.length > 0) {
      await sendData(allOddsData);
    }
  } catch (error) {
    console.error("Failed to update missed odds data:", error);
  }
  // 在函数的最后，确保弹窗被关闭
  setShowLoadingDialog(false);
}

function chunkArray<T>(array: T[], size: number): T[][] {
  const chunked = [];
  for (let i = 0; i < array.length; i += size) {
    chunked.push(array.slice(i, i + size));
  }
  return chunked;
}

function splitOddsData(
  oddsData: { hadList: any[]; hhadList: any[] },
  batchSize: number
) {
  const hadChunks = chunkArray(oddsData.hadList, batchSize);
  const hhadChunks = chunkArray(oddsData.hhadList, batchSize);

  const batches = [];
  for (let i = 0; i < Math.max(hadChunks.length, hhadChunks.length); i++) {
    batches.push({
      hadList: hadChunks[i] || [],
      hhadList: hhadChunks[i] || [],
    });
  }
  return batches;
}
