// import Link from "@mui/material/Link";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import Title from "./Title";
import {
  BACKEND_URL,
  BATCH_SIZE,
  DELAY_DURATION,
  splitDateRange,
  updateMatches,
  updateMissedOddsData,
} from "./helpers";

const tableHeaders = [
  { header: "比赛场次", key: "matchNumStr", width: "100px" },
  { header: "全称客队", key: "allAwayTeam", width: "350px" },
  { header: "全称主队", key: "allHomeTeam", width: "350px" },
  { header: "全场比分", key: "sectionsNo999", width: "150px" },
  { header: "联赛名称", key: "leagueName", width: "150px" },
  { header: "发布时间", key: "lastUpdated", width: "150px" },
  { header: "赔率类型", key: "oddsType", width: "150px" },
  { header: "负赔率", key: "aHAD", width: "150px" },
  { header: "让负赔率", key: "aHHAD", width: "150px" },
  { header: "负调整", key: "df", width: "150px" },
  { header: "平赔率", key: "dHAD", width: "150px" },
  { header: "让平赔率", key: "dHHAD", width: "150px" },
  { header: "胜赔率", key: "hHAD", width: "150px" },
  { header: "让胜赔率", key: "hHHAD", width: "150px" },
  { header: "目标线", key: "goalLineHAD", width: "150px" },
  { header: "让球数", key: "goalLineHHAD", width: "150px" },
  { header: "主调整", key: "af", width: "150px" },
  { header: "主调整标志", key: "hf", width: "150px" },
  // ... 其他 headers
];

function Orders() {
  const [data, setData] = useState([]);
  const today = new Date();
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 6); // Subtract 6 days to get a range of 7 days including today.

  const formatDate = (date: Date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  const [dateFilter, setDateFilter] = useState({
    matchBeginDate: formatDate(sevenDaysAgo),
    matchEndDate: formatDate(today),
  });

  const [teamFilter, setTeamFilter] = useState({
    allAwayTeam: "",
    allHomeTeam: "",
    leagueName: "",
  });
  const [page, setPage] = useState(0); // 新增分页状态
  const [rowsPerPage, setRowsPerPage] = useState(10); // 新增每页行数状态
  const [total, setTotal] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDateRangeDialog, setOpenDateRangeDialog] = useState(false);
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const [updateProgress, setUpdateProgress] = useState<number>(0);

  const fetchData = useCallback(
    async (update = false) => {
      const combinedFilters = {
        ...dateFilter,
        ...teamFilter,
        pageNo: page + 1, // 假设页数是从1开始的，而不是0，所以加1
        pageSize: rowsPerPage,
      };
      console.log("combinedFilters:", combinedFilters);
      // 首先本地请求更新一下数据库
      if (dateFilter.matchBeginDate && dateFilter.matchEndDate) {
        const beginDate = new Date(dateFilter.matchBeginDate);
        const endDate = new Date(dateFilter.matchEndDate);

        if (endDate < beginDate) {
          setOpenDialog(true);
          return; // 不继续执行后面的代码
        }
        if (update) {
          await updateMatches(
            dateFilter.matchBeginDate,
            dateFilter.matchEndDate
          );
          await updateMissedOddsData(setShowLoadingDialog, setUpdateProgress);
        }
      }
      // 过滤掉空的查询参数, 并将值都转为字符串
      const filteredParams = Object.fromEntries(
        Object.entries(combinedFilters)
          .filter(
            ([_, value]) =>
              value !== null && value !== undefined && value.toString().trim()
          )
          .map(([key, value]) => [key, String(value)])
      );

      // const match_url = `${BACKEND_URL}/getTotalMatchList?${new URLSearchParams(
      //   filteredParams
      // )}`;
      const odds_url = `${BACKEND_URL}/exportExcel?${new URLSearchParams(
        filteredParams
      )}`;

      const response = await fetch(odds_url);
      const result = await response.json();
      console.log(`fetch ${odds_url}:`, result);
      setData(result.oddsResults); // 根据返回数据的结构调整
      setTotal(result.total);
    },
    [dateFilter, teamFilter, page, rowsPerPage]
  );

  const handleExportToExcel = async () => {
    const combinedFilters = {
      ...dateFilter,
      ...teamFilter,
    };

    const filteredParams = Object.fromEntries(
      Object.entries(combinedFilters)
        .filter(
          ([_, value]) =>
            value !== null && value !== undefined && value.toString().trim()
        )
        .map(([key, value]) => [key, String(value)])
    );

    // Split the date range into chunks
    const { matchBeginDate, matchEndDate } = dateFilter;
    const startDate = new Date(matchBeginDate);
    const endDate = new Date(matchEndDate);
    const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // Check if date range exceeds 120 days
    if (diffDays > 120) {
      setOpenDateRangeDialog(true);
      return; // Exit the function early
    }
    const dateRanges = splitDateRange(matchBeginDate, matchEndDate, 30);
    console.log("dateRanges to export excel:", dateRanges);

    // Initiate the downloads for each chunk of the date range
    for (let range of dateRanges) {
      // Update the date filter parameters for the current chunk
      filteredParams.matchBeginDate = range.begin;
      filteredParams.matchEndDate = range.end;

      // Use a dynamically-created anchor tag to initiate download
      const link = document.createElement("a");
      link.href = `${BACKEND_URL}/exportExcel?${new URLSearchParams(
        filteredParams
      )}`;
      link.target = "_blank"; // This ensures it opens in a new window or tab
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup after triggering the download

      await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    // 新增页面更改函数
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // 新增每页行数更改函数
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // 重置为第一页
  };

  useEffect(() => {
    fetchData(false);
  }, [rowsPerPage, page, fetchData]); // 每页行数、页面更改时触发

  return (
    <React.Fragment>
      <Title>足彩数据报表</Title>

      {/* Filter section */}
      <div>
        <TextField
          label="球赛开始日期"
          type="date"
          value={dateFilter.matchBeginDate}
          onChange={(e) =>
            setDateFilter((prev) => ({
              ...prev,
              matchBeginDate: e.target.value,
            }))
          }
        />
        <TextField
          label="球赛结束日期"
          type="date"
          value={dateFilter.matchEndDate}
          onChange={(e) =>
            setDateFilter((prev) => ({ ...prev, matchEndDate: e.target.value }))
          }
        />
        <TextField
          label="联赛全称"
          value={teamFilter.leagueName}
          onChange={(e) =>
            setTeamFilter((prev) => ({ ...prev, leagueName: e.target.value }))
          }
        />

        <TextField
          label="全称客队"
          value={teamFilter.allAwayTeam}
          onChange={(e) =>
            setTeamFilter((prev) => ({ ...prev, allAwayTeam: e.target.value }))
          }
        />
        <TextField
          label="全称主队"
          value={teamFilter.allHomeTeam}
          onChange={(e) =>
            setTeamFilter((prev) => ({ ...prev, allHomeTeam: e.target.value }))
          }
        />
        {/* ... 同样的方式添加其他筛选条件 */}

        <Button onClick={() => fetchData(true)}>查询</Button>
        <Button onClick={handleExportToExcel}>导出EXCEL</Button>
        {/* Dialog组件 */}
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">警告</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              结束日期不能小于开始日期！
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              确认
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDateRangeDialog}
          onClose={() => setOpenDateRangeDialog(false)}
          aria-labelledby="date-range-dialog-title"
          aria-describedby="date-range-dialog-description"
        >
          <DialogTitle id="date-range-dialog-title">警告</DialogTitle>
          <DialogContent>
            <DialogContentText id="date-range-dialog-description">
              日期范围太大，不能超过120天！
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenDateRangeDialog(false)}
              color="primary"
            >
              确认
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={showLoadingDialog}>
          <DialogContent>
            <CircularProgress variant="determinate" value={updateProgress} />
            <DialogContentText>{`限速${BATCH_SIZE}次请求延迟${
              DELAY_DURATION / 1000
            }秒，正在更新... ${Math.round(
              updateProgress
            )}%`}</DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Table section */}
      <Table size="medium">
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <TableCell key={header.key}>{header.header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* 仅显示当前页面的数据 */}
          {data.map((row, index) => (
            <TableRow key={index}>
              {tableHeaders.map((header) => (
                <TableCell key={header.key} style={{ width: header.width }}>
                  {row[header.key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        {/* 分页组件 */}
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]} // 提供每页行数选项
              count={total} // 总行数
              rowsPerPage={rowsPerPage} // 每页行数
              page={page} // 当前页码
              onPageChange={handleChangePage} // 页码更改时的处理函数
              onRowsPerPageChange={handleChangeRowsPerPage} // 每页行数更改时的处理函数
              labelRowsPerPage="每页条数:"
            />
          </TableRow>
        </TableFooter>
      </Table>
    </React.Fragment>
  );
}

export default Orders;
